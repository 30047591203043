@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:ital,wght@0,700;1,700&display=swap');

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #474340;
}

h1,
h2,
h3,
h4 {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  margin-top: 0;
  margin-bottom: 1rem;
}

.wrapperBox {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 1rem;
  padding: 1rem;
}

.input {
  background-color: #f3f3f3;
  border: 0;
  font-size: 1rem;
  height: 3rem;
  padding: 0 1rem;
  border-radius: 1.5rem;
}

.selector {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1rem;
}

.selector option {
  font-size: 1rem;
}

.searchBox {
  background-color: #dcdcdc;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.formGroup {
  display: block;
}

.searchWrapper {
  display: flex;
  flex-direction: row;
}

.searchInput {
  flex-grow: 1;
  flex: 0 1 auto;
  border-radius: 1.5rem 0 0 1.5rem;
}

.searchButton {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
  border: 0;
  flex: 0 1 auto;
  font-size: 1.5rem;
  line-height: 0;
  width: 4rem;
  padding-left: 0.2rem;
  height: 3rem
}

.regionSelectionWrapper {
  background-color: #dcdcdc;
  padding: .3rem;
  display: flex;
  border-radius: 3rem;
  margin-bottom: 1rem;
}

.regionButton {
  flex: 1;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.barChartStyle {
  position: relative;
  display: flex;
  width: 100%;
}

.ownerWrapper {
  position: relative;
}

.ownerSelector {
  margin-top: 1rem;
  width: 100%;
}

.custom-tooltip {
  background-color: #4a4242;
  color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px #0000001a;
}

.custom-tooltip p {
  margin: .2rem 0;
  font-size: .9rem;
}

.custom-tooltip .hour {
  font-size: 1.2rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 250px;
  height: 35px;
}

.dataSourceText {
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-size: .7rem;
  color: #474340;
  
}


.autocomplete-container {
  /* relative position for at de absolut positionerede forslag får korrekt placering.*/
  position: relative;
  width: 100%;
}

.autocomplete-container input {
  /* Både input og forslag får samme bredde som omkringliggende DIV */
  width: 100%;
  box-sizing: border-box;
}

.dawa-autocomplete-suggestions {
  margin: 0.3em 0 0 0;
  padding: 0;
  text-align: left;
  border-radius: 0.31125em;
  background: #fcfcfc;
  box-shadow: 0 0.0625em 0.15625em #00000026;
  position: absolute;
  left: 0;
  right: 0;
  top: 2.75rem;
  z-index: 9999;
  overflow-y: auto;
  box-sizing: border-box;
  width: inherit;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion {
  margin: 0;
  list-style: none;
  cursor: pointer;
  padding: 0.4em 0.6em;
  color: #333;
  border: 0.0625em solid #ddd;
  border-bottom-width: 0;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-width: 0.0625em;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion.dawa-selected,
.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:hover {
  background: #f0f0f0;
}

/* For mobile phones: */
@media only screen and (max-width: 480px) {
  .bar-chart-container {
    width: 100%;
    height: 100%;
  }
}

/* For desktops: */
@media only screen and (min-width: 670px) {
  .formGroup {
    display: flex;
    flex-direction: row;
  }

  .addressWrapper {
    flex: 1;
    padding-right: .5rem;
  }

  .ownerWrapper {
    flex: 1;
    padding-left: .5rem;
  }

  .ownerSelector {
    margin: 0;
  }

  .autocomplete-container {
    max-width: 100%;
  }
}